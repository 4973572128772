import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import TagList from "../../components/TemplateTag/TagList";
import MainTitle from "../../components/Common/MainTitle";
import TutorialCard from "../../components/Common/TutorialCard";

export const pageQuery = graphql`
  query {
    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/code/tutorials//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
          }
        }
      }
    }

    filteredTut: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/code/tutorials//" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY년 MM월 DD일")
            title
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const menu = "code";
const section = "tutorials";

const tutorials = ({ location, data }) => {
  return (
    <Layout location={location}>
      <SEO title={section} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row>
            <Col lg={2} className="d-none d-lg-block">
              <aside className="sticky-item">
                <TagList
                  tags={data.allTags.group}
                  menu={menu}
                  section={section}
                />
                {/* <ADBox /> */}
              </aside>
            </Col>

            <Col lg={10}>
              {data.filteredTut.edges.length > 0 && (
                <div className="my-5">
                  <MainTitle title="Tutorials" />
                  <Row>
                    {data.filteredTut.edges.map(({ node }) => {
                      return (
                        <Col
                          key={node.fields.slug}
                          xl={4}
                          md={6}
                          style={{ marginBottom: "20px" }}
                        >
                          <TutorialCard node={node} />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default tutorials;
